'use client'
import { useError } from '@/app/components/providers/ErrorProvider'
import getClientSupabase from '@/app/utils/getClientSupabase'
import {
  Button,
  Textarea,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { useState, useEffect, ChangeEvent } from 'react'
import { Database, Tables, Enums } from "@/app/types/supabase";
import KpiCard from '@/app/components/home/kpiCard'
import { language, findLanguage } from '@/app/utils/data/platform-languages'
import InfoHelpPopover from '@/app/components/home/infoHelpPopover'

interface interviewQuestions {
  question: string
  duration: number
}

export default function GenerateQuestionsForm(
  { interviewId, merchantId, questionsGenerated, status, type, visibility, description, candidate_expectations, name, mojito_language_code, onQuestions, onFieldChange }: 
  { interviewId:string|null, questionsGenerated?:interviewQuestions[], merchantId:string, status: string, type: string, visibility: string, description: string|null, candidate_expectations: string|null, name: string|null, mojito_language_code: string|null, onQuestions:(questions:interviewQuestions[])=>void, onFieldChange:(key: string, value: string)=>void }
) {
  const [isLoading, setIsLoading] = useState(false)

  const supabase = getClientSupabase();
  const { showError } = useError()

  const [interviewTone, setInterviewTone] = useState<string>('relaxed');
  const [interviewLength, setInterviewLength] = useState<string>('6');

  const [genQuestions, setGenQuestions] = useState<interviewQuestions[]>(((questionsGenerated) && (questionsGenerated.length>0))? questionsGenerated: []);

  const [loading, setLoading] = useState<boolean>(false);
  const [applying, setApplying] = useState<boolean>(false);

  useEffect(() => {
    if ((questionsGenerated) && (questionsGenerated.length>0)) setGenQuestions(questionsGenerated);
  }, [questionsGenerated])


  function getInterview () {
    setLoading(true);
    setGenQuestions([]);

    supabase.functions.invoke('job-interview-questions-create', {
      body: JSON.stringify({ 
        position_name: name,
        merchant_id: merchantId,
        description: description,
        candidate_expectations: candidate_expectations,
        mojito_language_code: mojito_language_code,
        type: type,
        interview_id: interviewId, 
        interview_tone: interviewTone, 
        interview_length: interviewLength 
      })
    }).then((res)=>{
      try {
        if (res.error) { 
          showError(res.error?.message, 'error');
        } else {
          setGenQuestions(res.data as interviewQuestions[]);
          onQuestions(res.data as interviewQuestions[])
        }
      } catch (e) {

      }
      setLoading(false);

    })
  }

  const isInvalid = (!description) || (description===null) || (description==='') || (!candidate_expectations) || (candidate_expectations===null) || (candidate_expectations==='');

  return (
    <div>
          <div className='flex flex-col gap-3 mb-5'>
            <div className='flex flex-row gap-3 flex-wrap'>
              <KpiCard title='Type' value={type} firstLetterCapitalize={true} loaded={true} />
              <KpiCard firstLetterCapitalize title='Language' value={findLanguage(mojito_language_code)?.flag_emoji + ' '+ findLanguage(mojito_language_code)?.name_english} loaded={true} size='md' />
              <KpiCard title={(visibility==='merchant_invite')?'Position name':'Position name [Public Listing tab]'} value={name} firstLetterCapitalize={true} loaded={true} required={true} />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
              <Textarea
                aria-label='Interview Description'
                label={'Short Description (candidate visible)'}
                value={description || ''}
                name={'description'}
                isRequired
                onChange={(e)=> {
                  onFieldChange('description',e.target.value)
                }}
                endContent={<InfoHelpPopover type='interview-description' />}
                radius="sm"
              />

              <Textarea
                aria-label='Interview Description'
                label={'Candidate Expectations (AI instructions)'}
                value={candidate_expectations || ''}
                name={'description'}
                endContent={<InfoHelpPopover type='interview-candidate-expectations' />}
                isRequired
                onChange={(e)=> {
                  onFieldChange('candidate_expectations',e.target.value)
                }}
                radius="sm"
              />
            </div>

            {/*
            <KpiCard title={(visibility==='merchant_invite')?'Short Description':'Short Description [Public Listing tab]'} size='sm' value={formDescription} loaded={true} required={true} />
            <KpiCard title={(visibility==='merchant_invite')?'Candidate Expectations':'Candidate Expectations [Interview Setup tab]'} size='sm' value={formCandidateExpectations} loaded={true} required={true} /> */}
          </div>

        {isInvalid?
        <div className='text-danger mb-5'>Please complete all required fields before trying to generate this interview.</div>
        :
        <div className='flex flex-col gap-5 mb-5'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
            <Select
              label="Select tone of the Interview"
              size='sm'
              className='text-black'
              disallowEmptySelection={true}
              defaultSelectedKeys={[interviewTone]}
              onChange={(e) => { setInterviewTone(e.target.value);}}
            >
              <SelectItem key={'relaxed'}>Relaxed</SelectItem>
              <SelectItem key={'professional'}>Professional</SelectItem>
            </Select>

            <Select
              label="Select Duration"
              size='sm'
              className='text-black'
              disallowEmptySelection={true}
              defaultSelectedKeys={[interviewLength]}
              onChange={(e) => { setInterviewLength(e.target.value);}}
            >
              <SelectItem key={'6'}>Short [6 questions]</SelectItem>
              <SelectItem key={'9'}>Medium [9 questions]</SelectItem>
              <SelectItem key={'12'}>Long [12 questions]</SelectItem>
            </Select>
          </div>
          {genQuestions.length===0 && <Button variant='solid' color='primary' size='sm' isDisabled={isInvalid || loading || genQuestions.length>0} isLoading={loading} onClick={()=> { getInterview(); }}>Generate Interview</Button> }
          </div>
        }
        {genQuestions.length>0 && 
            <div className='mb-5'>
            <ol className='with-numbers text-black'>
              {genQuestions.map((q)=> {
                return <li key={q.question}>{q.question}</li>
              })}
            </ol>
            </div>
          }
    </div>
  )
}
