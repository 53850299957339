'use client'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Chip,
  Card,
  Image,
  ModalFooter,
  ModalContent,
} from '@nextui-org/react'
import React, { ChangeEvent, useState, Key, useEffect } from 'react'
import { ClickIcon } from '../../interview_creator/components/ClickIcon'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { Database, Tables, Enums } from "@/app/types/supabase";
import { language } from '@/app/utils/data/platform-languages'

export default function TemplateModalSelect({
  className = '',
  selectedTemplate,
  myMerchantId,
  searchLanguageCode,
  searchLanguageName,
  searchLanguageFlag,
  searchInteractive,
  showModal,
  handleSelect,
  handleClose,
}: {
  className?: string | undefined
  selectedTemplate: Tables<'interview_templates'> | undefined
  myMerchantId: string
  searchLanguageCode: string,
  searchLanguageName: string,
  searchLanguageFlag: string,
  searchInteractive?: boolean | undefined,
  showModal: boolean
  handleSelect: (selected: Tables<'interview_templates'>|undefined) => void
  handleClose: () => void
}) {
  const handleCloseModal = () => {
    handleClose();
  }

  const supabase = getClientSupabase()
  const [templates, setTemplates] = useState<Tables<'interview_templates'>[]>([]);
  const [templateSel, setTemplateSel] = useState<Tables<'interview_templates'>|undefined>(selectedTemplate);

  useEffect(() => {
      let query = supabase
      .from('interview_templates')
      .select('*')
      .or(`and(status.eq.active,visibility.eq.public),and(status.eq.active,merchant_id.eq."${myMerchantId}")`);

      if (searchInteractive!==undefined) {
        query = (searchInteractive===true?query.eq('type', 'interactive_heygen'):query.neq('type', 'interactive_heygen'));
      }

      query.eq('mojito_language_code', searchLanguageCode)
      .then((res) => {
        if ((res?.data!==undefined) && (res?.data!==null)) {
          setTemplates(res.data);
        }
      });
  }, [searchLanguageCode, searchInteractive]);

  useEffect(() => {
    setTemplateSel(selectedTemplate);
  }, [selectedTemplate]);

  return (
    <>
      <Modal isOpen={showModal} onClose={handleCloseModal} size='4xl' scrollBehavior="inside">
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1 text-black">Select Interview Avatar</ModalHeader>
            <ModalBody>
            <div>
              <div className={'text-xs'}>Selected Language</div>
              <div>{searchLanguageFlag} {searchLanguageName}</div>
            </div>
            <div className="flex flex-row gap-5">
              <div className='w-2/3 bg-gray-50 flex p-4 flex-row flex-wrap gap-x-2 gap-y-5 h-fit w-full'>
              {templates.map((template) => (
                <Card isPressable shadow='none' key={'temp-'+template.id} className={((templateSel?.id === template.id) ? 'bg-sky-400' : '')+' p-2 rounded-xl relative '} onPress={()=> { 
                  setTemplateSel(template);
                  //handleSelect(template);
                }}
                >
                    <div key={'video-'+template.id}  className={'relative flex justify-center w-40 aspect-video p-0 m-0 place-items-center'}>
                        {(template.video_background_remove===true && template.background_url!==null) && <img alt='Interview background' style={(template.background_blur!==null)?{filter: `blur(${template.background_blur*10}px)`, WebkitFilter: `blur(${template.background_blur*10}px)`}:{}} className='rounded-xl absolute resize-none' src={template.background_url} /> }
                        {(template.video_avatar_image_url!==null) && <img alt='Interview avatar' className='rounded-xl absolute resize-none h-full' src={template.video_avatar_image_url} /> }
                    </div>
                    {template.visibility!=='public' && <div className='absolute' style={{top:-6, right:-4}}><Chip size='sm' variant='light' color='warning' className='bg-white'>Custom</Chip></div> }
                    {template.type==='interactive_heygen' &&
                    <div className='absolute flex flex-row gap-1 z-100' style={{bottom:-6, right:-4}}>
                        <Chip size='sm' variant='light' color='success' className='cursor-pointer bg-white'>Interactive</Chip>
                    </div>
                    }
                  </Card>

                ))}
              </div>
              <div className=" w-1/3 bg-gray-50 p-4 flex flex-col gap-5">
                <div>
                  <div className={'text-xs'}>Preview</div>
                  {templateSel &&
                  <div>
                    {(templateSel?.media_url?<video src={templateSel.media_url} controls><track kind="caption" /></video>:
                    <div className={'relative flex justify-center w-36 aspect-video p-0 m-0 place-items-center'}>
                        {(templateSel.video_background_remove===true && templateSel.background_url!==null) && <img alt='Interview background' style={(templateSel.background_blur!==null)?{filter: `blur(${templateSel.background_blur*10}px)`, WebkitFilter: `blur(${templateSel.background_blur*10}px)`}:{}} className='rounded-xl absolute resize-none' src={templateSel.background_url} /> }
                        {(templateSel.video_avatar_image_url!==null) && <img alt='Interview avatar' className='absolute resize-none h-full' src={templateSel.video_avatar_image_url} /> }
                    </div>
                    )}
                  </div>
                  }
                </div>
                <div className={'text-black'}>{templateSel?.name}</div>
              </div>
            </div>

            </ModalBody>
            <ModalFooter>
              <Button aria-label='Approve' isDisabled={(templateSel===undefined)} color="primary" size='sm' onPress={() => {
                handleSelect(templateSel);
                handleClose()
              }}>
                Select
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  )
}
