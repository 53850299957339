import {Skeleton} from "@nextui-org/skeleton";
import {Link} from "@nextui-org/link";
import { formatedDate, smartDate } from '@/app/components/core/SmartDates'
import InfoHelpPopover, { PopoverType  } from '@/app/components/home/infoHelpPopover'
import { ReactNode } from 'react'

interface KpiCardProps {
    title?: string | undefined
    titleReact?: ReactNode | undefined
    value: number | string | undefined | null
    classname?: string 
    position?: 'row' | 'col'
    size?: 'lg' | 'md' | 'sm'
    type?: 'string' | 'currency' | 'currencyUsd' | 'link' | 'date' | 'email'
    color?: 'danger' | 'warning' | 'default' | 'secondary'
    loaded: boolean
    required?: boolean
    infoPopover?: PopoverType | undefined,
    firstLetterCapitalize?: boolean
  }

function capitalizeFirstLetter(str: string|undefined|null):string {
    if ((str!==undefined) && (str!==null))
        return str.charAt(0).toUpperCase()+str.slice(1);
    return '';
}

export default function KpiCard(
    { title, titleReact, value, position = 'col', type='string', size='lg', classname='', firstLetterCapitalize=false, loaded, required=false, color='default', infoPopover }: KpiCardProps) {
    const flexDirectionClass =
    position === 'row' ? 'flex-row items-center gap-1' : 'flex-col'

    let formatedValue = value;
    let formatedValueString:string = (value!==undefined && value!==null && (typeof value !== 'number'))?value:'';

    if (type==='currency') formatedValue = '€'+value;
    if (type==='currencyUsd') formatedValue = '$'+value;
    if ((firstLetterCapitalize) && (typeof formatedValue !=='number')) formatedValue = capitalizeFirstLetter(formatedValue);

    const formatedString = (value===undefined || value===null?'':value.toString());

    return (
          <div className={`flex ${flexDirectionClass} ${classname}`}>
            <div className={'text-xs text-zinc-400'}>{titleReact?titleReact:title}</div>
            <div className="flex flex-row gap-2">
                <Skeleton isLoaded={loaded} className="rounded-lg">
                    {(type==='link')?
                        <Link color={(color==='default'?'secondary':color)} className={"underline-offset-4 underline decoration-dashed hover:decoration-solid "+(size==='lg'?'text-lg':(size==='md'?'text-md':'text-sm'))} href={((formatedValueString.includes('https://') || formatedValueString.includes('http://'))?'':'https://')+formatedValueString} target='_blank'>{formatedValue}</Link>
                    :(type==='email')?
                        <Link color={(color==='default'?'secondary':color)} className={"underline-offset-4 underline decoration-dashed hover:decoration-solid "+(size==='lg'?'text-lg':(size==='md'?'text-md':'text-sm'))} href={'mailto:'+formatedValueString}>{formatedValue}</Link>
                    :(type==='date'? 
                    (
                        <div className="flex flex-col">
                        <p className={(color==='default'?'text-black':'text-'+color)+' '+(size==='lg'?'text-lg':(size==='md'?'text-md':'text-sm'))}>{smartDate(formatedString)}</p>
                        {(formatedDate(formatedString)!==smartDate(formatedString) && <p className="text-xs text-zinc-600">{formatedDate(formatedString)}</p>)}
                        </div>
                    )
                    :(
                        <div className={(color==='default'?'text-black':'text-'+color)+' '+(size==='lg'?'text-lg':(size==='md'?'text-md':'text-sm'))}>{((value===undefined || value===null || value === '') && required) ? 
                            <div className="text-warning flex flex-row gap-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                </svg><div>Missing</div>
                            </div>
                        :formatedValue}</div>
                    ))}
                </Skeleton>
                {infoPopover && <InfoHelpPopover type={infoPopover} />}
            </div>
        </div>
    )
}